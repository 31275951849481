import React from 'react';
import styled from 'styled-components';
import * as Widget from './Widget';

const Wrapper = styled.div`
  height: 600px;

  @media screen and (max-width: 600px) {
    height: 300px;
  }

  ${props => props.extraCss || ''};
`;

const Banner = ({ extraCss, image }) => (
  <Wrapper extraCss={extraCss}>
    <Widget.BgImage image={image} />
  </Wrapper>
);

export default Banner;
