import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import Selectors from '../../Selectors';
import { withPage } from '../../Page';
import Banner from '../../Components/Banner';
import ItemInCart from '../../Components/ItemInCart';

class ProductDetailPage extends React.Component {
  render() {
    let { item } = this.props;

    return (
      <div>
        <Banner image={item.image} />

        <ProductDetailPage.ContentLayout>
          <div>
            <h2>{item.name}</h2>
            <p>{item.description_tw}</p>
          </div>

          <ItemInCart itemId={item.id} display="product" />
        </ProductDetailPage.ContentLayout>
      </div>
    );
  }

  static ContentLayout = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    padding: 20px;
    margin: 0 auto;

    & > :first-child {
      flex: 1;
      margin-right: 40px;
      width: 600px;
    }

    & > :nth-child(2) {
      width: 400px;
      align-self: flex-start;
    }
  `;
}

export default withPage(connect(
  (state, ownProps) => ({
    item: Selectors.getProduct(state, ownProps.pageContext.itemId), 
  })
)(ProductDetailPage));
